import {styled} from "@mui/material/styles";
import {IconButton} from "@mui/material";
export const EditShapeModalBackgroundContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const EditShapeModalContainer = styled('div')`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  background-color: #FFFFFF;
  position: relative;
  min-width: 500px;
  min-height: 600px;
`;

export const CloseIconButton = styled(IconButton)`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 2px;
  right: 2px;
`;

export const ShapeEditorContainer = styled('div')`
  margin: 18px 18px 0 18px;
  box-sizing: border-box;
`;
