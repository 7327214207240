import React, {useLayoutEffect} from "react";
import styles from "./PageWrapper.module.scss";
import {SpinnerConnected} from "../spinner/SpinnerConnected";
import {Footer} from "../footer/footer";
import {PopupDialogConnected} from "../popupDialog/PopupDialogConnected";
import {NavigationBarConnected} from "../navigationBar/navigationBarConnected";
import {UploadPopupConnected} from "../uploadPopup/UploadPopupConnected";
import {getStoreUtils} from "../../store/utils";
import {getBalance} from "../../ducks/auth";
import {CommentPopupConnected} from "../popupcomment/CommentPopupConnected";
import {UploadSuccessSnackbar} from "../snackbar/snackbar";
import {DifferenceUpload} from "../DifferenceUploadPopup/DifferenceUpload";
import {EditShapeModal} from "../EditShapeModal/edit-shape-modal";
import {useSelector} from "react-redux";
import {selectEditShapeModalState} from "../../selectors/monitoring";


export default function PageWrapper ({ children }) {
    useLayoutEffect(() => {
        getStoreUtils().dispatch(getBalance())
    }, []);

    const editShapeModalState = useSelector(selectEditShapeModalState);

    return <div className={styles.wrapperContainer}>
        {editShapeModalState?.isOpen && <EditShapeModal/>}
        <PopupDialogConnected/>
        <UploadPopupConnected/>
        <DifferenceUpload/>
        <SpinnerConnected/>
        <CommentPopupConnected/>
        <NavigationBarConnected/>
        <UploadSuccessSnackbar/>
        {/*@ts-ignore*/}
        <div className={`${styles.pagesContainer} ${window.REACT_APP_HIDE_FOOTER ? styles.hideFooter : styles.displayFooter }`}>
            {children}
        </div>
        {/*@ts-ignore*/}
        {!window.REACT_APP_HIDE_FOOTER && <Footer/>}
    </div>
}
