import * as React from "react";
import {Button, IconButton, TextField} from "@mui/material";
import styles from "./CommentPopup.module.scss";
import {CommentInputIdPrefix} from "../DifferenceListSidebar/CommentInput";
import {getStoreUtils} from "../../store/utils";
import {push} from "connected-react-router";
import {ROUTES} from "../../app/Routes";
import {MonitoringStatuses} from "../../ducks/types";

export interface CommentPopupStateProps {
    comment: string;
    isOpen: boolean;
}

export interface CommentPopupDispatchProps {
    setComment: (value: string) => void;
    setCommentPopupOpen: (flag: boolean) => void;
    updateComment: (status?: number) => void;
    routeTo: (route: string | null, id?: number) => void;
}

export type CommentPopupProps = CommentPopupStateProps & CommentPopupDispatchProps;

export class CommentPopup extends React.Component<CommentPopupProps> {

    state = {
        message: '',
        checked: false
    }

    public render() {
        const {isOpen} = this.props;
        return isOpen? (
            <div
                onClick={() => {
                    this.props.setCommentPopupOpen(false);
                }}
                className={styles.popupBack}>
                <div
                    onClick={(e)=>{
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                    }}
                   className={styles.popupContainer}>
                    <div className={styles.messageContainer}>
                        <IconButton
                            onClick={() => {
                                this.props.setCommentPopupOpen(false);
                            }}
                            className={styles.closeIcon}
                        >
                        </IconButton>
                        <TextField
                            id={`${CommentInputIdPrefix}CommentPopup`}
                            placeholder={'Текст комментария'}
                            value={this.props.comment}
                            sx={{
                                marginTop: "20px",
                                backgroundColor: "#FFF",
                                width: '425px',
                            }}
                            multiline
                            minRows={4}
                            maxRows={6}
                            variant="outlined"
                            onChange={(event) => {
                                const value = event.target.value;
                                this.props.setComment(value ? value : '');
                            }}
                        />
                    </div>
                    <div className={styles.buttonContainer}>
                        <Button
                            variant={'contained'}
                            className={styles.buttonCancel}
                            onClick={() => {
                                this.props.setComment('');
                            }}
                        >
                            Очистить
                        </Button>
                        <Button
                            className={`${styles.buttonAction}`}
                            onClick={() => {
                                this.props.updateComment();
                                this.props.setCommentPopupOpen(false);
                            }}
                            variant={'contained'}
                        >
                            Сохранить
                        </Button>
                        <Button
                            className={`${styles.buttonActionLong}`}
                            onClick={() => {
                                this.props.updateComment(MonitoringStatuses.FINISHED);
                                this.props.setCommentPopupOpen(false);
                                getStoreUtils().dispatch(push(ROUTES.Home))
                            }}
                            variant={'contained'}
                        >
                            Сохранить и завершить
                        </Button>

                    </div>
                </div>
            </div>
        ) : (
            <></>
        );
    }
}
