import {DifferenceBarPosition, FirstDocumentPosition} from "../components/navigationBar/navigationBar";
import {IDifferenceData, DocumentImage, IMonitoringItem} from "../api/monitoringApi";
import {CommentInputProps} from "../components/DifferenceListSidebar/CommentInput";

export enum PageParts {
    Right = "Right",
    Left = "Left",
    Comment = "Comment",
}

export interface IExchangeParams {
    panelPosition: DifferenceBarPosition | undefined;
    docPosition: FirstDocumentPosition | undefined;
    docFilterList: string[];
}

export type DeleteRowProps = {
    pairIds: number[];
}

export enum MonitoringStatuses {
    NEW = 0,
    SENDING = 1,
    COMPARED = 2,
    HAS_DIFFERENCES = 3,
    HAS_NO_DIFFERENCES = 4,
    FINISHED = 5,
    DELETED = 6,
    UPLOADING = 7,
}

export interface ITechData {
    recognitionServiceFirstDocumentId: number,
    recognitionServiceSecondDocumentId: number,
}

export interface IDetailedPair {
    id: number;
    discrepancies: IDifferenceData[];
    discrepancyRatio: number;
    state: GET_DOCUMENTS_STATE;
    statusId: number;
    techData: ITechData,
    comment: string;
    files: {
        mimeType: string;
        name: string;
        place: number;
    }[];
}

export enum GET_DOCUMENTS_STATE {
    Done = "Done",
    Other = 'Other',
    Comparing = 'Comparing',
    QueuedToComparing = 'QueuedToComparing',
    Recognizing = 'Recognizing',
    Created = 'Created',
    Error = 'Error',
    NotFound = 'NotFound',
}

export enum DocNumber {
    FIRST,
    SECOND
}

export enum SortDirections {
    ASC = "asc",
    DESC = "desc"
}

export const MONITORING_STATUSES = {
    0: "Новый",
    1: "Отправляется",
    2: "Сравнение",
    3: "Есть расхождения",
    4: "Нет расхождений",
    5: "Завершен",
    6: "Удален",
    7: "Загрузка",
}

export type IMonitoringState = {
    list: IMonitoringItem[];
    selectedDiff: string | undefined;
    leftScale: number;
    rightScale: number;
    differenceData: IDifferenceData[];
    isCommentInputShown: boolean;
    detailedDocumentIdLeft: string;
    detailedDocumentIdRight: string;
    pairId: number | undefined;
    leftDetailedImages: DocumentImage[];
    rightDetailedImages: DocumentImage[];
    isTrackDisabled: boolean;
    isMagnifierActive: boolean;
    searchOptions: string[];
    sortType: SortDirections | undefined;
    sortOrderBy: number;
    listPage: number;
    zoomCount: number;
    scrollSync: boolean;
    isChangingView: boolean;
    docFilterList: string[];
    menuOpen: boolean;
    pageLoadingState: {
        isLoading: boolean;
        isComparing: boolean;
    } | undefined;
    isChangingDetailedDiff: boolean;
    panelPosition: DifferenceBarPosition | undefined;
    docPosition: FirstDocumentPosition | undefined;
    detailedPair: IDetailedPair | undefined;
    selectedRowIds: number[];
    differenceComment: DifferenceCommentState | undefined,
    differenceLink: DifferenceLinkState | undefined,
    differenceUploadPopup: DifferenceUploadPopupState | undefined,
    editShapeModal: EditShape,
    imageShapes: ImageShape,
}

export type ImageShapeCommon = Record<DocumentImage['imageId'], Vertex[]> | undefined;

type ImageShape = {
    [PageParts.Left]: ImageShapeCommon;
    [PageParts.Right]: ImageShapeCommon;
};

export type SetImageShapeProps = {
    imageId: DocumentImage['imageId'];
    vertexList: Vertex[];
    pagePart: PageParts;
};

export enum Directions {
    Right = 'right',
    Left = 'left',
}

export type DifferenceCommentState = Omit<CommentInputProps, 'topIndex'>;

export type DifferenceLinkState = {
    hash: string;
};

export type DifferenceUploadPopupState = {
    link: string;
};

export type EditShape = Pick<DocumentImage, 'imageId' | 'fileUrl'> & { isOpen: boolean, pagePart: PageParts };

export type Vertex = {
    x: number;
    y: number;
    rateWidth?: number;
    rateHeight?: number;
}
