import {FC, useEffect, useMemo, useRef, useState} from "react";
import {
    CloseIconButton,
    EditShapeModalBackgroundContainer,
    EditShapeModalContainer, ShapeEditorContainer
} from "./edit-shape-modal-styles";
import {getStoreUtils} from "../../store/utils";
import {setEditShapeModalState} from "../../ducks/montitoring";
import {useSelector} from "react-redux";
import {selectEditShapeModalState, selectImageShapes} from "../../selectors/monitoring";
import CloseIcon from '@mui/icons-material/Close';
import {SxProps, Theme} from "@mui/material";
import {ShapeEditor} from "../ShapeEditor/shape-editor";
import {getObjectFitSize} from "../../helper/other";

const iconSx: SxProps<Theme> = {
    width: 18,
    height: 18,
};

const shapeRate = 140;

export const EditShapeModal: FC = () => {
    const {dispatch} = getStoreUtils();
    const {isOpen, imageId, fileUrl, pagePart} = useSelector(selectEditShapeModalState);
    const image = useRef(new Image());
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const imageShape = useSelector(selectImageShapes);
    const verticesList =  (imageShape && imageShape[pagePart]) ? imageShape[pagePart][imageId] : [];

    useEffect(() => {
        return () => {
            setIsLoaded(() => false);
            image.current = null;
        }
    }, []);

    useEffect(() => {
        image.current.src = fileUrl; // Используем импортированное изображение
        image.current.onload = () => {
            setIsLoaded(true)
        };
    }, [fileUrl]);

    const imageSizes = useMemo(() => {
        return getObjectFitSize(true, Math.round(window.innerWidth - shapeRate), Math.round(window.innerHeight - shapeRate), image?.current?.naturalWidth, image?.current?.naturalHeight,)
    }, [image?.current, isLoaded]);

    const { rateWidth, rateHeight} = useMemo(() => {
        const width = image?.current?.width ?? 1;
        const height = image?.current?.height ?? 1;
        const rateWidth = imageSizes?.width/width;
        const rateHeight = imageSizes?.height/height;

        return {
            rateWidth, rateHeight
        }
    }, [image?.current?.width, image?.current?.height, isLoaded, imageSizes?.width, imageSizes?.height])

    if(!isOpen) return null;

    const onClose = () => {
        dispatch(setEditShapeModalState());
    }
    // console.log("image.current.src", image.current.src)
    console.log("imageSizes?.width", imageSizes?.width, 'image?.current?.width', image?.current?.width, rateWidth)
    console.log("rateWidth", rateWidth)


    return isOpen ? <EditShapeModalBackgroundContainer>
        <EditShapeModalContainer>
            <CloseIconButton
                size={'small'}
                onClick={onClose}
            >
                <CloseIcon sx={iconSx}/>
            </CloseIconButton>
            <ShapeEditorContainer>

                {isLoaded && <ShapeEditor
                    pagePart={pagePart}
                    verticesList={verticesList ?? []}
                    fileUrl={fileUrl}
                    imageId={imageId}
                    {...imageSizes}
                    rateHeight={rateHeight === Infinity ? 1 : rateHeight}
                    rateWidth={rateWidth === Infinity ? 1 : rateWidth}
                />}
            </ShapeEditorContainer>
        </EditShapeModalContainer>
    </EditShapeModalBackgroundContainer> : null
}
