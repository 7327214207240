import {EditShape, PageParts, Vertex} from "../../ducks/types";
import React, {FC, useEffect, useRef, useState} from "react";
import {ObjectFitSize} from "../../helper/other";
import {ActionButtonsContainer, ButtonsContainer, ShapeEditorContainer} from "./shape-editor-styles";
import {Button, IconButton, ThemeProvider} from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import {theme} from "../../theme/theme";
import {getStoreUtils} from "../../store/utils";
import {setAllImageShapes, setEditShapeModalState, setImageShape} from "../../ducks/montitoring";

type Props = Omit<EditShape, 'isOpen'> & ObjectFitSize & { verticesList: Vertex[], rateHeight: number, rateWidth: number, pagePart: PageParts };
export const ShapeEditor: FC<Props> = ({fileUrl, width, height, imageId, verticesList, rateHeight, rateWidth, pagePart}) => {
    const {dispatch} = getStoreUtils();
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [vertices, setVertices] = useState<Vertex[]>(verticesList);
    const [defaultVertices, setDefaultVertices] = useState<Vertex[]>(verticesList);

    const onApply = () => {
        dispatch(setImageShape({imageId, vertexList: vertices.map((el) => ({...el, rateHeight, rateWidth})), pagePart}));
        setTimeout(() => {
            dispatch(setEditShapeModalState());
        }, 5);
    }

    const onApplyAll = () => {
        dispatch(setAllImageShapes({pagePart, vertexList: vertices.map((el) => ({...el, rateHeight, rateWidth}))}));
        setTimeout(() => {
            dispatch(setEditShapeModalState());
        }, 5);
    }

    const onRestore = () => {
        setVertices([...defaultVertices]);
    }

    useEffect(() => {
        return () => setVertices([]);
    }, []);

    useEffect(() => {
        if (width && height && !isNaN(width) && !isNaN(height) && !vertices.length) {
            const fixedWidth = Math.ceil(width);
            const fixedHeight = Math.ceil(height);

            const newVertices = [
                {x: 0, y: 0},
                {x: fixedWidth / 2, y: 0},
                {x: fixedWidth, y: 0},
                {x: fixedWidth, y: fixedHeight / 2},
                {x: fixedWidth, y: fixedHeight},
                {x: fixedWidth / 2, y: fixedHeight},
                {x: 0, y: fixedHeight},
                {x: 0, y: fixedHeight / 2},
            ]

            setVertices(() => (newVertices));
            setDefaultVertices(() => (newVertices));
            setTimeout(() => drawCanvas(newVertices));
        }
    }, [width, height]);


    const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
    const image = useRef(new Image());

    useEffect(() => {
        image.current.src = fileUrl; // Используем импортированное изображение
        image.current.onload = () => {
            drawCanvas();
        };
    }, [fileUrl, vertices]);

    const drawCanvas = (newValues?: Vertex[]) => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;
        const verticesToDraw = newValues ?? vertices;

        ctx.clearRect(0, 0, canvas.width, canvas.height); // Очистка канваса

        // Рисуем изображение на канвасе
        ctx.drawImage(image.current, 0, 0, canvas.width, canvas.height);

        if (verticesToDraw.length) {
            // Отрисовка многоугольника
            ctx.strokeStyle = 'rgba(0, 191, 255, 0.8)';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(verticesToDraw[0].x, verticesToDraw[0].y);
            verticesToDraw.forEach((vertex) => {
                ctx.lineTo(vertex.x, vertex.y);
            });
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = 'rgba(0, 191, 255, 0.1)';
            ctx.fill();
        }

        // Отрисовка вершин
        verticesToDraw.forEach((vertex) => {
            ctx.fillStyle = 'rgb(5,183,238)';
            ctx.beginPath();
            ctx.arc(vertex.x, vertex.y, 8, 0, Math.PI * 2);
            ctx.fill();
            ctx.closePath();
        });
    };

    const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const rect = canvas.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const mouseY = e.clientY - rect.top;

        // Проверка на вершины
        const index = vertices.findIndex(vertex =>
            Math.hypot(vertex.x - mouseX, vertex.y - mouseY) < 16 // Проверка, находится ли курсор рядом с вершиной
        );

        if (index !== -1) {
            setDraggingIndex(index);
        }
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
        if (draggingIndex === null) return;

        const canvas = canvasRef.current;
        if (!canvas) return;

        const rect = canvas.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const mouseY = e.clientY - rect.top;

        const newVertices = [...vertices];
        newVertices[draggingIndex] = {x: mouseX, y: mouseY};

        setVertices(newVertices);
        drawCanvas();
    };

    const handleMouseUp = () => {
        setDraggingIndex(null);
    };

    return (
        <ShapeEditorContainer>
            <canvas
                ref={canvasRef}
                width={Math.ceil(width) ?? 500} // Увеличиваем ширину до 800
                height={Math.ceil(height) ?? 600} // Увеличиваем высоту до 800
                style={{border: '1px solid black'}}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
            />
            <ButtonsContainer>
                <ThemeProvider theme={theme}>
                    <ActionButtonsContainer>
                        <Button
                            onClick={onApply}
                            sx={{color: '#FFF'}}
                            variant={'contained'}
                        >
                            Применить
                        </Button>
                        <Button
                            onClick={onApplyAll}
                            variant={'outlined'}
                        >
                            Применить для всех
                        </Button>
                    </ActionButtonsContainer>
                    <IconButton
                        onClick={onRestore}
                    >
                        <RefreshIcon/>
                    </IconButton>
                </ThemeProvider>
            </ButtonsContainer>
        </ShapeEditorContainer>
    );
}
