import React, {FC, useEffect, useLayoutEffect, useRef, useState} from "react";
import styles from './navigationBar.module.scss';
import {
    Badge,
    Button,
    Checkbox,
    ClickAwayListener,
    FormControlLabel,
    Grow,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Slider,
} from "@mui/material";
import {styled, ThemeProvider} from '@mui/material/styles';
import docTopIconActive from './docTopIconActive.svg';
import saveToPdf from './saveToPdf.svg';
import docLeftIconActive from './docLeftIconActive.svg';
import {Icon} from '@iconify/react';
import {
    DEFAULT_DIFFERENCE_DOC_FILTERS_LIST, DifferenceInfo,
    DOC_DIFFERENCE_FILTERS_DISABLED_LIST,
    DOC_DIFFERENCE_FILTERS_LIST,
    DOC_FILTERS, getDifferenceRatePercent,
    SECONDS_IN_WEEK
} from "../../helper/other";
import {ROUTES} from "../../app/Routes";
import {Link} from "react-router-dom";
import scrollSyncIcon from './twoArrowsSync.svg';
import scrollAsyncIcon from './twoArrowsAsync.svg';
import scaleIcon from './scale.png'
import {Delete} from "@material-ui/icons";
import {PopupElement} from "../../ducks/popup";
import IosShareIcon from '@mui/icons-material/IosShare';
import {CreateHashProps, ITechReport} from "../../api/monitoringApi";
import DoneIcon from '@mui/icons-material/Done';
import CommentIcon from "@mui/icons-material/Comment";
import ReportIcon from "@mui/icons-material/Report";
import {differenceSideBarTheme} from "../DifferenceListSidebar/theme";
import Tooltip from "@mui/material/Tooltip";
import {DeleteRowProps, ITechData, MonitoringStatuses} from "../../ducks/types";
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';

const iconSx = {
    width: '40px',
    height: '40px',
    color: '#000'
};

export enum DifferenceBarPosition {
    RIGHT = "RIGHT",
    LEFT = "LEFT"
}

export enum FirstDocumentPosition {
    TOP = "TOP",
    LEFT = "LEFT"
}

export interface NavigationBarStateProps {
    login: string;
    panelPosition: DifferenceBarPosition | undefined;
    docPosition: FirstDocumentPosition | undefined;
    balance: number;
    header: string;
    isLoggedIn: boolean;
    isDetailedPage: boolean;
    isListPage: boolean;
    scrollSync: boolean;
    isMagnifierActive: boolean;
    menuOpen: boolean;
    isRestartPairButtonDisabled: boolean;
    isVmAuth: boolean;
    zoomCount: number;
    docFilterList: string[];
    activeAccountId: string;
    isUserInfo: boolean;
    isPublic: boolean;
    canGetBalance: boolean;
    selectedRowIds: number[];
    pairId: number;
    techData: ITechData;
    report: string;
    comment: string;
    discrepancyRatio: number;
    filteredDiscrepancy: string[];
    differenceRatioData: DifferenceInfo;
}

export interface NavigationBarDispatchProps {
    onLogout: VoidFunction;
    getPdf: VoidFunction;
    getExtendParams: VoidFunction;
    getBalance: VoidFunction;
    setZoomCount: (zoom: number) => void;
    setUploadPopupOpen: (isOpen: boolean) => void;
    setScrollSync: (flag: boolean) => void;
    setMenuOpen: (flag: boolean) => void;
    setIsMagnifierActive: (flag: boolean) => void;
    updateDocFilterList: (list: string[]) => void;
    setExtendParams: VoidFunction;
    setDifferenceBarPosition: (position: DifferenceBarPosition) => void;
    setFirstDocumentPosition: (position: FirstDocumentPosition) => void;
    setLeftScale: (leftScale: number) => void;
    setRightScale: (rightScale: number) => void;
    popupPush: (popup: PopupElement) => void;
    deleteTableRows: (props: DeleteRowProps) => void;
    createHash: (props: CreateHashProps) => void;
    updatePairStatus: (status: MonitoringStatuses) => void;
    sendTechnicalReport: (props: ITechReport) => void;
    updateTechReportText: (value: string) => void;
    setCommentPopupOpen: (flag: boolean) => void;
    routeTo: (route: string | null, id?: number) => void;
    restartPackage: VoidFunction;
}

type NavigationBarProps = NavigationBarStateProps & NavigationBarDispatchProps;

const CustomSlider = styled(Slider)({
    color: '#F57B20',
});

const CustomBadge = styled(Badge)<{ isFilter?: boolean }>`
  .MuiBadge-dot {
    background-color: ${({isFilter}) => (isFilter ? 'red' : '#F57B20')};
  }
`;

const CheckboxBadge = styled(CustomBadge)`
  .MuiBadge-badge {
    top: 6px;
    right: 5px;
  }

,
`;

const DeleteIcon = styled(Delete)`
  width: 24px;
  height: 24px;
  color: #000000;
`;

const StyledLinkButton = styled(Button)`
  border-color: #F57B20;

  &:hover {
    border-color: #F57B20;
  }
;
  font-size: 15px;
  line-height: 15px;
  color: #000000;
  text-transform: none;
  height: 35px;
  @media (max-width: 1600px) {
    font-size: 14px;
    line-height: 14px;
  }
`;

export const NavigationBar: FC<NavigationBarProps> = ({
                                                          isDetailedPage,
                                                          discrepancyRatio,
                                                          differenceRatioData,
                                                          routeTo,
                                                          comment,
                                                          report,
                                                          techData,
                                                          isPublic,
                                                          isListPage,
                                                          selectedRowIds,
                                                          getExtendParams,
                                                          setExtendParams,
                                                          panelPosition,
                                                          updatePairStatus,
                                                          sendTechnicalReport,
                                                          updateTechReportText,
                                                          deleteTableRows,
                                                          docPosition,
                                                          pairId,
                                                          setCommentPopupOpen,
                                                          createHash,
                                                          popupPush,
                                                          activeAccountId,
                                                          isUserInfo,
                                                          canGetBalance,
                                                          getBalance,
                                                          setUploadPopupOpen,
                                                          getPdf,
                                                          docFilterList,
                                                          updateDocFilterList,
                                                          setIsMagnifierActive,
                                                          isMagnifierActive,
                                                          setZoomCount,
                                                          setLeftScale,
                                                          setRightScale,
                                                          zoomCount,
                                                          setFirstDocumentPosition,
                                                          setDifferenceBarPosition,
                                                          scrollSync,
                                                          setScrollSync,
                                                          isLoggedIn,
                                                          login,
                                                          onLogout,
                                                          balance,
                                                          filteredDiscrepancy,
                                                          isVmAuth,
                                                          isRestartPairButtonDisabled,
                                                          restartPackage
                                                      }) => {
    const isPanelLeft = panelPosition === DifferenceBarPosition.LEFT || !panelPosition;
    const isDocumentTop = docPosition === FirstDocumentPosition.TOP || !docPosition;
    const scaleMenuRef = useRef();
    const [openUserMenu, setOpenUserMenu] = useState(false);
    const [openScaleMenu, setOpenScaleMenu] = useState(false);
    const [openFilterMenu, setOpenFilterMenu] = useState(false);
    const [openStructureMenu, setOpenStructureMenu] = useState(false);
    const isCommentInvisible = !comment || comment?.length === 0;
    const {wordsCount, marksCount, pagesAdded, pagesDeleted} = differenceRatioData;

    useEffect((() => {
        if (!activeAccountId && isUserInfo) {
            window.location.href = process.env.REACT_APP_CORRECT_BASE_API + '/Integration/IntegrateWithComparerService?redirectToDocshow=True'
        }

        if (isDetailedPage) {
            getExtendParams();
        }
    }), [activeAccountId, isUserInfo]);

    useLayoutEffect(() => {
        canGetBalance && getBalance();
    }, []);

    const STRUCTURE = [
        {
            title: "Панель изменений слева, документы горизонтально",
            differenceBarPosition: DifferenceBarPosition.LEFT,
            firstDocumentPosition: FirstDocumentPosition.TOP,
            structure: () => isPanelLeft && isDocumentTop,
            imgClassName: `${styles.documentPositionIconVertical}`,
            src: docTopIconActive,
        },
        {
            title: "Панель изменений слева, документы вертикально",
            differenceBarPosition: DifferenceBarPosition.LEFT,
            firstDocumentPosition: FirstDocumentPosition.LEFT,
            structure: () => isPanelLeft && !isDocumentTop,
            imgClassName: `${styles.documentPositionIcon} ${styles.mirror}`,
            src: docLeftIconActive,
        },
        {
            title: "Панель изменений справа, документы вертикально",
            differenceBarPosition: DifferenceBarPosition.RIGHT,
            firstDocumentPosition: FirstDocumentPosition.LEFT,
            structure: () => !isPanelLeft && !isDocumentTop,
            imgClassName: `${styles.documentPositionIcon}`,
            src: docLeftIconActive,
        },
        {
            title: "Панель изменений справа, документы горизонтально",
            differenceBarPosition: DifferenceBarPosition.RIGHT,
            firstDocumentPosition: FirstDocumentPosition.TOP,
            structure: () => !isPanelLeft && isDocumentTop,
            imgClassName: `${styles.documentPositionIconVertical} ${styles.mirror}`,
            src: docTopIconActive,
        }
    ];
    useEffect(() => {
        const newElem = STRUCTURE.find(el => el.firstDocumentPosition === docPosition && el.differenceBarPosition === panelPosition);
        if (newElem) setActiveStructure(newElem);
    }, [panelPosition, docPosition]);

    const onDeleteTableRows = () => {
        popupPush({
            actionTitle: "Да",
            cancelTitle: 'Отмена',
            actionVisible: true,
            data: [`Вы действительно хотите удалить выбранные документы?`],
            actionHandler: () => {
                deleteTableRows({pairIds: selectedRowIds})
            },
            cancelVisible: true,
        })
    }

    const [activeStructure, setActiveStructure] = useState(STRUCTURE[0]);

    const anchorComp = useRef(null);
    const anchorStruct = useRef(null);


    const handleFilterMenuClose = () => {
        if (openFilterMenu) {
            setOpenFilterMenu(() => false)
            setExtendParams();
        }
    }

    const handleFilterMenu = () => {
        setOpenFilterMenu((prevValue) => !prevValue)
        if (!!openFilterMenu) {
            setExtendParams();
        }
    }
    const onFinish = () => {
        popupPush({
            actionTitle: "Завершить",
            actionVisible: true,
            data: [`Вы действительно хотите изменить статус на "Завершен"?`],
            actionHandler: () => {
                updatePairStatus(MonitoringStatuses.FINISHED);
            },
            cancelVisible: true,
        });
    }

    const onCommentIcon = () => {
        setCommentPopupOpen(true);
    };
    const onTechReport = () => {
        popupPush({
            technicalSupport: true,
            actionTitle: "Отправить",
            cancelTitle: "Отменить",
            actionVisible: true,
            actionHandler: () => {
                sendTechnicalReport({
                    message: report,
                    packageId: [techData.recognitionServiceFirstDocumentId, techData.recognitionServiceSecondDocumentId],
                    subject: "DocShow: Сравнение документов",
                })
                routeTo("/")
            },
            cancelHandler: () => {
                updateTechReportText("");
            },
            data: ["Вы действительно хотите отправить информацию об ошибке в техническую поддержку?"],
            cancelVisible: true,
            flag: true,
        });
    }

    const onDelete = () => {
        popupPush({
            actionTitle: "Удалить",
            actionVisible: true,
            data: ["Вы действительно хотите удалить пару?"],
            actionHandler: () => {
                updatePairStatus(MonitoringStatuses.DELETED);
            },
            cancelVisible: true,
        });

    };

    const logo = <div className={styles.iconContainer}>
        <div className={styles.logo}>
        </div>
        <div className={styles.title}>
            <div>Сравнение</div>
            <div>документов</div>
        </div>
    </div>;

    const logoElement = isPublic ? logo : <Link style={{textDecoration: "none"}} to={ROUTES.Home}>
        {logo}
    </Link>;

    return <header
        className={styles.container}
    >
        <div className={styles.leftContainer}>
            {/*@ts-ignore*/}
            {(!window.REACT_APP_HIDE_LOGO) && logoElement}
            {(isDetailedPage && !isPublic) && <Link to={ROUTES.Home}>
                <StyledLinkButton
                    variant={'outlined'}
                >
                    Список документов
                </StyledLinkButton>
            </Link>}
            {/*@ts-ignore*/}
            {(!window.REACT_APP_HIDE_UPLOAD_BUTTON && !isVmAuth) && <Button
                variant={'contained'}
                onClick={() => {
                    setUploadPopupOpen(true);
                }}
                className={`${styles.link} 
                ${styles.linkActive}`}
            >
                +&nbsp;&nbsp;Загрузить файл
            </Button>}

            {(isListPage && selectedRowIds.length !== 0) && <IconButton
                title={'Удалить'}
                onClick={onDeleteTableRows}
            >
                <DeleteIcon/>
            </IconButton>}
        </div>
        {isDetailedPage && <div className={styles.actionsContainer}>
            <div className={styles.actionContainer}>
                <div className={styles.actionContainerRow}>
                    <div
                        className={styles.diffFilterContainer}
                    >
                        {/*@ts-ignore*/}
                        {!window.REACT_APP_HIDE_FILTER_BUTTON && <ClickAwayListener onClickAway={handleFilterMenuClose}>
                            <IconButton
                                title={'Настройки сравнения'}
                                ref={anchorComp}
                                size={"small"}
                                className={`${openFilterMenu ? styles.selectedItem : ""}`}
                                onClick={handleFilterMenu}
                            >
                                <CustomBadge isFilter variant="dot" invisible={filteredDiscrepancy.length === 0}>
                                    <Icon icon="gala:settings" width="28" color={`#000`} height="28"/>
                                </CustomBadge>
                                <Popper
                                    anchorEl={anchorComp ? anchorComp.current : null}
                                    className={styles.topPanelRightAvatarMenu}
                                    open={openFilterMenu}
                                    transition
                                    disablePortal
                                    sx={{top: 80,}}
                                    placement={'bottom-start'}
                                >
                                    {({TransitionProps, placement}) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin:
                                                    placement === "bottom" ? "center top" : "center bottom"
                                            }}
                                        >
                                            <Paper
                                            >
                                                <MenuList
                                                    sx={{
                                                        marginTop: `10px`,
                                                        alignItems: 'start'
                                                    }}
                                                    id="menu-list-grow"
                                                >
                                                    {DOC_DIFFERENCE_FILTERS_LIST.map((elem) => {
                                                        const name = DOC_FILTERS[elem] ? DOC_FILTERS[elem] : "";
                                                        return <MenuItem
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                            }}
                                                            disabled={DOC_DIFFERENCE_FILTERS_DISABLED_LIST.includes(elem)}
                                                            sx={{
                                                                fontSize: '14px',
                                                                paddingTop: '0px',
                                                                paddingBottom: '0px',
                                                            }}
                                                        >
                                                            <FormControlLabel
                                                                sx={{
                                                                    fontSize: '14px'
                                                                }}
                                                                control={
                                                                    <CheckboxBadge

                                                                        isFilter
                                                                        variant="dot"
                                                                        invisible={!filteredDiscrepancy.includes(elem)}
                                                                    >
                                                                        <Checkbox
                                                                            sx={{
                                                                                height: '32px',
                                                                                width: '32px',
                                                                                color: `#F57B20`,
                                                                                '&.Mui-checked': {
                                                                                    color: `#F57B20`,
                                                                                },
                                                                                fontSize: '14px',
                                                                            }}
                                                                            checked={docFilterList.includes(elem)}
                                                                            defaultChecked
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                            }}
                                                                            onChange={(e) => {
                                                                                e.stopPropagation();
                                                                                e.preventDefault();
                                                                                if (docFilterList.includes(elem)) {
                                                                                    updateDocFilterList(docFilterList.filter(el => el !== elem));
                                                                                } else {
                                                                                    updateDocFilterList([...docFilterList, elem]);
                                                                                }

                                                                            }}
                                                                        />
                                                                    </CheckboxBadge>
                                                                } label={<span
                                                                style={{fontSize: '14px'}}>{`${name}`}</span>}/>
                                                        </MenuItem>
                                                    })}
                                                    <div className={styles.divider}/>
                                                    <MenuItem
                                                        sx={{
                                                            fontSize: '14px',
                                                            paddingTop: '0px',
                                                            paddingBottom: '0px',
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            sx={{
                                                                fontSize: '14px'
                                                            }}
                                                            control={
                                                                <Checkbox
                                                                    sx={{
                                                                        height: '32px',
                                                                        width: '32px',
                                                                        color: `#F57B20`,
                                                                        '&.Mui-checked': {
                                                                            color: `#F57B20`,
                                                                        },
                                                                        fontSize: '14px',
                                                                    }}
                                                                    checked={docFilterList.length === (DOC_DIFFERENCE_FILTERS_LIST.length)}
                                                                    defaultChecked
                                                                    onChange={() => {
                                                                        if (docFilterList.length === (DOC_DIFFERENCE_FILTERS_LIST.length)) {
                                                                            updateDocFilterList([...DEFAULT_DIFFERENCE_DOC_FILTERS_LIST]);
                                                                        } else {
                                                                            updateDocFilterList([...DOC_DIFFERENCE_FILTERS_LIST.filter(elem => !DOC_DIFFERENCE_FILTERS_DISABLED_LIST.includes(elem))]);
                                                                        }
                                                                    }}
                                                                />} label={<span
                                                            style={{fontSize: '14px'}}>{`Включить/отключить все фильтры`}</span>}/>
                                                    </MenuItem>
                                                    <div
                                                        className={styles.setButtonContainer}
                                                    >
                                                        <Button
                                                            onClick={() => {
                                                                updateDocFilterList([...DEFAULT_DIFFERENCE_DOC_FILTERS_LIST]);
                                                            }}
                                                            className={styles.setDefaultButton}
                                                        >
                                                            Фильтры по умолчанию
                                                        </Button>
                                                    </div>
                                                </MenuList>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </IconButton>
                        </ClickAwayListener>}
                    </div>
                    <IconButton
                        sx={{
                            height: '38px',
                            width: '38px',
                            color: '#000'
                        }}
                        title={'увеличительное стекло'}
                        onClick={() => {
                            setIsMagnifierActive(!isMagnifierActive)
                        }}
                        size={"small"}
                        className={` ${styles.buttonContainer} ${isMagnifierActive ? styles.selectedItem : ""}`}
                    >
                        <Icon style={{
                            width: '34 !important',
                            height: '34 !important',
                            color: `#000`
                        }} icon="radix-icons:magnifying-glass" width="34" height="34"/>
                    </IconButton>
                    <IconButton
                        ref={scaleMenuRef}
                        onClick={() => setOpenScaleMenu(true)}
                        className={` ${styles.buttonContainer}`}
                        size={"small"}
                    >
                        <span
                            className={styles.diffFilterContainer}
                            title={'Масштаб'}
                        >
                            <img width={'25px'} height={'25px'} style={{objectFit: 'contain'}} src={scaleIcon}
                                 alt={''}/>
                        </span>
                    </IconButton>
                    <Popper
                        anchorEl={scaleMenuRef ? scaleMenuRef.current : null}
                        className={styles.topPanelRightAvatarMenu}
                        open={openScaleMenu}
                        transition
                        placement={'bottom'}
                    >
                        {({TransitionProps, placement}) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === "bottom" ? "center top" : "center bottom"
                                }}
                            >
                                <Paper
                                    sx={{
                                        height: '216px'
                                    }}
                                >
                                    <ClickAwayListener onClickAway={
                                        () => {
                                            setOpenScaleMenu(false);
                                        }
                                    }>
                                        <CustomSlider
                                            onChange={(_, value) => {
                                                setZoomCount(value as number)
                                                setLeftScale((value as number) / 100)
                                                setRightScale((value as number) / 100)
                                            }}
                                            sx={{
                                                '& input[type="range"]': {
                                                    WebkitAppearance: 'slider-vertical',
                                                },
                                                height: 200,
                                                marginTop: '8px',
                                                marginBottom: '8px',
                                            }}
                                            orientation="vertical"
                                            step={10}
                                            min={10}
                                            max={200}
                                            value={zoomCount}
                                            valueLabelDisplay="auto"
                                            getAriaValueText={() => `${zoomCount}%`}
                                            valueLabelFormat={() => `${zoomCount}%`}
                                            aria-labelledby="non-linear-slider"
                                        />
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                    <div
                        className={styles.diffFilterContainer}
                    >
                        <IconButton
                            title={activeStructure.title}
                            ref={anchorStruct}
                            className={`${openStructureMenu ? styles.selectedItem : ""}`}
                        >
                            <IconButton
                                size={"small"}
                                sx={{
                                    height: '28px',
                                    width: '28px',
                                    color: '#000'
                                }}
                                onClick={() => {
                                    setOpenStructureMenu(!openStructureMenu)
                                }}
                            >
                                <img className={activeStructure.imgClassName} width={34} height={32}
                                     src={activeStructure.src} alt={''}/>
                            </IconButton>
                            <Popper
                                anchorEl={anchorStruct ? anchorStruct.current : null}
                                className={styles.topPanelRightAvatarMenu}
                                open={openStructureMenu}
                                transition
                                sx={{top: 80,}}
                                placement={'bottom-start'}
                            >
                                {({TransitionProps, placement}) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === "bottom" ? "center top" : "center bottom"
                                        }}
                                    >
                                        <Paper
                                        >
                                            <ClickAwayListener onClickAway={
                                                () => {
                                                    setOpenStructureMenu(false);
                                                }
                                            }>
                                                <MenuList
                                                    sx={{
                                                        marginTop: `10px`,
                                                        paddingTop: '0px',
                                                        alignItems: 'start'
                                                    }}
                                                    id="menu-list-grow"
                                                >
                                                    {STRUCTURE.map(elem =>
                                                        <MenuItem
                                                            className={elem.structure() && styles.selectedItem}
                                                            sx={{
                                                                fontSize: '14px',
                                                                paddingBottom: '0px',
                                                                paddingLeft: '6px',
                                                                paddingTop: '4px'
                                                            }}
                                                            onClick={() => {
                                                                setFirstDocumentPosition(elem.firstDocumentPosition);
                                                                setDifferenceBarPosition(elem.differenceBarPosition);
                                                                setOpenStructureMenu(false)
                                                                setActiveStructure(elem)
                                                                setExtendParams();
                                                            }}
                                                        >
                                                            <IconButton
                                                                size={"small"}
                                                                sx={{
                                                                    height: '28px',
                                                                    width: '28px',
                                                                    color: '#000',
                                                                    marginRight: '10px',
                                                                    paddingLeft: '8px',
                                                                    paddingTop: '4px'
                                                                }}
                                                                className={`${elem.structure() && styles.selectedItem}`}
                                                            >
                                                                <img className={elem.imgClassName} width={34}
                                                                     height={32}
                                                                     src={elem.src} alt={''}/>
                                                            </IconButton>
                                                            {elem.title}
                                                        </MenuItem>)}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </IconButton>
                    </div>
                    {!scrollSync && <IconButton
                        onClick={() => {
                            setScrollSync(true)
                        }}
                        sx={iconSx}
                    >
                        <img title={'Прокрутка не синхронизирована'} style={{objectFit: 'contain'}} width={38}
                             height={30}
                             src={scrollAsyncIcon} alt={''}/>
                    </IconButton>}
                    {scrollSync && <IconButton
                        onClick={() => {
                            setScrollSync(false)
                        }}
                        sx={iconSx}
                    >
                        <img title={'Прокрутка синхронизирована'} style={{objectFit: 'contain'}} width={38} height={30}
                             src={scrollSyncIcon} alt={''}/>
                    </IconButton>}
                    <IconButton
                        disabled={isRestartPairButtonDisabled}
                        onClick={restartPackage}
                        sx={iconSx}
                    >
                        <Rotate90DegreesCcwIcon/>
                    </IconButton>
                </div>
                <ThemeProvider theme={differenceSideBarTheme(`#F2F7FA`)}>
                    <Tooltip
                        placement={'bottom'}
                        title={<div className={styles.infoText}>
                            <div className={styles.infoTextRow}>
                <span>
                     % соответствия:
                </span>
                                {getDifferenceRatePercent(discrepancyRatio) ?? 0}
                            </div>
                            <div className={styles.infoTextRow}>
                <span>
                     Знаков изменено:
                </span>
                                <span>
                    {marksCount ?? 0}
                </span>
                            </div>
                            <div className={styles.infoTextRow}>
                <span>
                     Слов отличается:
                </span>
                                <span>
                    {wordsCount ?? 0}
                </span>
                            </div>
                            <div className={styles.infoTextRow}>
                <span>
                     Страниц добавлено:
                </span>
                                <span>
                    {pagesAdded}
                </span>
                            </div>
                            <div className={styles.infoTextRow}>
                <span>
                     Страниц удалено:
                </span>
                                <span>
                    {pagesDeleted}
                </span>
                            </div>
                        </div>}
                    >
                        <div
                            className={`${styles.actionContainerInfo} ${discrepancyRatio === null ? styles.actionContainerInfoEmpty : ''}`}>
                            {discrepancyRatio !== null && <span> Документ соответствует:</span>}
                            {discrepancyRatio !== null && (getDifferenceRatePercent(discrepancyRatio) ?? 0)}
                            {discrepancyRatio === null && <span style={{visibility: 'hidden'}}>00%</span>}
                        </div>
                    </Tooltip>
                </ThemeProvider>
                <div className={styles.actionContainerRow}>
                    {/*@ts-ignore*/}
                    {(!window.REACT_APP_HIDE_ACTION_BUTTONS) && <React.Fragment>
                        {(isDetailedPage && !isPublic) && <IconButton
                            title={"Оставить комментарий"}
                            onClick={onCommentIcon}
                            sx={iconSx}
                        >
                            <CustomBadge color="primary" variant="dot" invisible={isCommentInvisible}>
                                <CommentIcon/>
                            </CustomBadge>

                        </IconButton>}
                        {(isDetailedPage && !isPublic) && <IconButton
                            title={"Сообщить о проблеме"}
                            onClick={onTechReport}
                            sx={iconSx}
                        >
                            <ReportIcon sx={{...iconSx, width: 26, height: 26,}}/>
                        </IconButton>}
                        {(isDetailedPage && !isPublic) && <IconButton
                            title={'Завершить'}
                            onClick={onFinish}
                            sx={iconSx}
                        >
                            <DoneIcon/>
                        </IconButton>}
                        {(isDetailedPage && !isPublic) && <IconButton
                            title={'Удалить'}
                            onClick={onDelete}
                            sx={iconSx}
                        >
                            <DeleteIcon/>
                        </IconButton>}
                    </React.Fragment>}
                </div>
            </div>
            {isDetailedPage && <div className={styles.shareContainer}>
                {!isPublic && <IconButton
                    title={"Экспорт в PDF"}
                    size={"small"}
                    sx={{
                        height: '40px',
                        width: '40px',
                        color: '#000'
                    }}
                    onClick={() => {
                        getPdf()
                    }}
                >
                    <img className={styles.exportToPdfButton} src={saveToPdf} alt={''}/>
                </IconButton>}

                {(!isPublic) && <IconButton
                    title={'Поделиться'}
                    onClick={() => {
                        createHash({
                            pairId,
                            lifeTime: SECONDS_IN_WEEK,
                        })
                    }}
                    sx={iconSx}
                >
                    <IosShareIcon/>
                </IconButton>}

            </div>}
        </div>}

        <div className={styles.topPanelRight}>
            {!isPublic && <>
                {/*@ts-ignore*/}
                {((!window.REACT_APP_HIDE_BALANCE || !isPublic) && !isVmAuth) && <div className={styles.row}>
                    {isLoggedIn ? <div className={styles.itemColumn}>
                            <div
                                onClick={() => setOpenUserMenu(!openUserMenu)}
                                className={styles.topPanelRightAvatarCont}
                            >
                                <span className={styles.topPanelRightAvatarUserName}>{login}</span>
                                <span className={styles.arrowDown}/>
                            </div>
                            <Popper
                                className={styles.topPanelRightAvatarMenu}
                                open={openUserMenu}
                                transition
                                disablePortal
                                style={{top: 60, left: "auto", right: 50}}
                            >
                                {({TransitionProps, placement}) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === "bottom" ? "center top" : "center bottom"
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={() => setOpenUserMenu(false)}>
                                                <MenuList
                                                    id="menu-list-grow"
                                                    onKeyDown={() => setOpenUserMenu(!openUserMenu)}
                                                >
                                                    <MenuItem
                                                        onClick={() => setOpenUserMenu(false)}
                                                    >
                                                        <a
                                                            target={'_blank'}
                                                            href={'https://lkk.correct.su/Billing/Tariffs'}
                                                        >
                                                            Личный кабинет</a>
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            onLogout()
                                                            setOpenUserMenu(false)
                                                        }}>
                                                        Выход
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                            <div className={styles.row}>
                                <div className={styles.rowItem}>
                                    <div className={styles.text}>
                                        Баланс:
                                    </div>
                                    <div className={styles.textBalance}>
                                        {balance} страниц
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div
                            className={styles.rowItem}
                        >
                            <a
                                href={`${process.env.REACT_APP_CORRECT_LK_LOGIN_LINK}`}
                            >
                                <Button
                                    variant={'contained'}
                                    className={styles.buttonAction}
                                    size={"small"}
                                >
                                    Войти
                                </Button>
                            </a>
                            <a href={`${process.env.REACT_APP_CORRECT_LK_REGISTRATION_LINK}`}>
                                <Button
                                    variant={'contained'}
                                    className={styles.buttonCancel}
                                    size={"small"}
                                >
                                    Регистрация
                                </Button>
                            </a>
                        </div>}
                </div>}
            </>}
            {isPublic &&
                <IconButton
                    title={"Экспорт в PDF"}
                    size={"small"}
                    sx={{
                        height: '40px',
                        width: '40px',
                        color: '#000'
                    }}
                    onClick={() => {
                        getPdf()
                    }}
                >
                    <img className={styles.exportToPdfButton} src={saveToPdf} alt={''}/>
                </IconButton>}
        </div>
    </header>

}
