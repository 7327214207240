import styles from './App.module.scss';
import React from "react";
import {Provider} from "react-redux";
import {ConnectedRouter} from "connected-react-router";
import {store} from "../store/store";
import {ROUTES} from "./Routes";
import {initStoreUtils} from "../store/utils";
import {history} from "../store/rootReducers";
import {PrivateRouteConnected} from "./PrivateRoute";
import {MonitoringPageConnected} from "../pages/MonitoringPage/MonitoringPageConnected";
import {AuthPageConnected} from "../pages/AuthPage/AuthPageConnected";
import {customControllerUtils} from "../abortController/abortController";
import {DifferencePagePrivate} from "../pages/DifferencePage/DifferencePagePrivate";
import {DifferencePagePublic} from "../pages/DifferencePage/DifferencePagePublic";
import {Route} from "react-router";
import {AuthorizationPage} from "../pages/AuthorizationPage/AuthorizationPage";

initStoreUtils(store.dispatch, store.getState);

if (!customControllerUtils.controller) {
    customControllerUtils.setNewController();
}

function App() {
    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <div className={styles.App}>
                    <PrivateRouteConnected path={ROUTES.Home} exact component={MonitoringPageConnected}/>
                    <PrivateRouteConnected path={`${ROUTES.Doc}/:id`} exact component={DifferencePagePrivate}/>
                    <PrivateRouteConnected path={`${ROUTES.DocPublic}/:id`} exact component={DifferencePagePublic}/>
                    <PrivateRouteConnected path={ROUTES.Docs} exact component={MonitoringPageConnected}/>
                    <PrivateRouteConnected path={ROUTES.Auth} component={AuthPageConnected}/>
                    <Route path={ROUTES.Authorization} component={AuthorizationPage}/>
                </div>
            </ConnectedRouter>
        </Provider>

    );
}

export default App;
