import PageWrapper from "../../components/pageWrapper/PageWrapper";
import React, {FC, useState} from "react";
import {AuthorizationContainer} from "./AuthorizationPageStyles";
import {Button, TextField, ThemeProvider} from "@mui/material";
import {TextFieldProps} from "@mui/material/TextField/TextField";
import {theme} from "../../theme/theme";
import {getStoreUtils} from "../../store/utils";
import {popupPush, spinnerMinusStatus, spinnerPlusStatus} from "../../ducks/popup";
import {setBoolInLocalStorage, vmAuthToken} from "../../helper/other";
import {push} from "connected-react-router";
import {ROUTES} from "../../app/Routes";
import {getMonitoringApiObj} from "../../api/monitoringApi";

const sx = {width: '300px'};

export const AuthorizationPage: FC = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const { dispatch } = getStoreUtils();

    const onLogin = () => {
        dispatch(spinnerPlusStatus());
        getMonitoringApiObj()
            .getVmId()
            .then(response => {
                // Проверка, что ответ успешный
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text(); // Получаем ответ как plain text
            })
            .then(data => {
                if(login === 'Admin' && password === data) {
                    setBoolInLocalStorage(vmAuthToken, true);
                    dispatch(push(ROUTES.Home));
                }
            })
            .catch(error => {
                dispatch(spinnerMinusStatus());
                console.error('There was a problem with the fetch operation:', error);
                dispatch(popupPush({
                    actionTitle: "Хорошо",
                    actionVisible: true,
                    data: ["Произошла ошибка. Возможно неверный логин или пароль."],
                    actionHandler: () => {
                    },
                    cancelVisible: false,
                }));
            })
            .finally(() => {
                dispatch(spinnerMinusStatus());
            })
    }

    const handleLoginChange: TextFieldProps['onChange'] = (event) => {
        const value = event.target.value;
        setLogin(() => value);
    }
    const handlePasswordChange: TextFieldProps['onChange'] = (event) => {
        const value = event.target.value;
        setPassword(() => value);
    }

    return <PageWrapper>
        <ThemeProvider theme={theme}>
            <AuthorizationContainer>
                <TextField
                    color={'secondary'}
                    value={login}
                    onChange={handleLoginChange}
                    label={'Логин'}
                    size="medium"
                    InputLabelProps={{shrink: true}}
                    sx={sx}
                    margin={"normal"}
                />
                <TextField
                    color={'secondary'}
                    value={password}
                    onChange={handlePasswordChange}
                    label={'Пароль'}
                    size="medium"
                    InputLabelProps={{shrink: true}}
                    sx={sx}
                    margin={"normal"}
                />
                <Button
                    disabled={!login.trim() || !password.trim()}
                    onClick={onLogin}
                    color={'secondary'}
                    variant={'contained'}
                    sx={{...sx, color: '#fff'}}
                >
                    Войти
                </Button>
            </AuthorizationContainer>
        </ThemeProvider>
    </PageWrapper>
}
